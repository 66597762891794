body {
  background-color: #000;
  color: #f5f5f5;
  padding: 30px 15px 100px 15px;
  font-family: "Roboto", sans-serif;
}

.header-on-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  width: 100%;
  background-color: #0d703f;
  z-index: -10;
}

[data-bs-theme="dark"] {
  --bs-body-bg: #111;
}

.money-green {
  background-color: #0d703f !important;
  border-color: #0d703f !important;
  outline-color: #0d703f !important;
}

.balance-card,
.form-select,
.form-control,
.d-grid {
  margin-bottom: 20px;
  font-size: 16px;
}

.form-select {
  border: none !important;
  outline: none !important;
}

.balance-card {
  min-height: 150px;
}

.balance-card .card-body {
  padding: 40px 10px;
  border-radius: 15px;
}

.balance-card .card-title {
  font-size: 25px;
}

.balance-card .card-text {
  font-size: 15px;
}

.balance-card.remaining {
  min-height: 105.5px;
  background: transparent;
  border: none;
  margin-bottom: 0;
  text-align: center;
}

.budget-type-wrapper {
  margin-bottom: 30px;
}

.balance-card.remaining .card-body {
  padding: 0 10px;
}

.balance-card.remaining .card-title {
  font-size: 60px;
  margin-bottom: 0;
}

.balance-card.remaining .card-text {
  font-size: 17px;
  margin-bottom: 0;
  letter-spacing: 1px;
  font-weight: 100;
}

.header {
  margin-bottom: 15px;
  font-weight: 100;
}

.header-right {
  text-align: right;
}

.header-img {
  border-radius: 30%;
  width: 50px;
}

.welcome-back-text {
  margin-top: 5px;
  font-size: 12px;
  letter-spacing: 1px;
  color: #ccc;
}

.welcome-back-text .name {
  font-size: 16px;
  letter-spacing: normal;
  color: #fff;
}

.signout-out-icon {
  margin-top: 10px;
  font-size: 15px;
  cursor: pointer;
}

.editable-card-wrapper {
  position: relative;
}

.editable-card-wrapper .edit-action-btn {
  position: absolute;
  bottom: 10px;
  right: 20px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

.editable-card-wrapper .edit-cancel-action-btn {
  position: absolute;
  bottom: 10px;
  right: 70px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

.editable-card-wrapper .edit-action-btn:hover,
.editable-card-wrapper .edit-cancel-action-btn:hover {
  text-decoration: underline;
}

.action-button,
.login-btn {
  font-size: 16px;
  border-radius: 15px;
}

.login-btn {
  margin-bottom: 36px;
}

.action-button.reset {
  margin-top: 50px;
}

.amount-after-decimal-point {
  font-size: 50%;
  color: #999;
}

.history-item-row {
  border-bottom: 1px solid #333;
  padding: 10px 0;
  font-size: 16px;
}

.amount-col {
  text-align: right;
  margin-top: 10px;
}

.history-item-row:last-child {
  border-bottom: none;
}

.history-header {
  margin-bottom: 20px;
}

.history-datetime {
  margin-bottom: 0;
  font-size: 12px;
  color: #777;
}

.history-budget-type-seelctor-col .budget-type-selector {
  font-size: 12px;
  color: #777;
  background-color: #333;
  margin-left: 0;
}

.balance-card-secondary {
  letter-spacing: 1px;
  font-weight: bold;
  color: #111;
}

.sticky-add-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  z-index: 100;
  font-size: 60px;
  color: #0d703f;
  background: #000;
  padding: 15px 0;
  cursor: pointer;
}

.add-modal .modal-content {
  background-color: rgb(33, 37, 41);
}

.category-dropdown-label {
  font-size: 14px;
  margin-left: 2px;
}

.low-balance {
  color: #800020;
}

.budget-category-delete-icon {
  margin-top: 15px;
  cursor: pointer;
}

.chart-wrapper,
.history-wrapper {
  margin-bottom: 20px;
}

select.budget-type-selector {
  display: inline;
  width: 120px;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  font-size: 16px;
  margin-left: 5px;
  margin-right: 5px;
}

.add-modal-title {
  font-size: 16px;
}

.budget-type-wrapper .budget-type-selector {
  clear: both;
  display: block;
  width: 100%;
  margin: 0;
  margin-bottom: 15px;
  font-size: 20px;
  padding: 5px 10px;
}
